//import {useNavigate} from 'react-router-dom';
//import {c_log} from './Mods'
//import TextField from '@mui/material/TextField'
import '../index.css'
import React,{Fragment,useState,useEffect} from "react"
import ExusrEdit from './Ex_usr_Edit';

const Ex_usr =() =>{
    
        const[examen,setTodos] = useState([]);
        
        const getTodos = async ()=>{
        try {
            const response = await fetch("http://localhost:5000/examen");
            const jsonData = await response.json();
            setTodos(jsonData);
            } catch (error) {
                console.error(error.message)
            }
        }
    
    useEffect(()=>{
        getTodos();
    },[]);

    console.log(examen);

    return(
        <Fragment> 
        
        <h1 className="text-center mt-5">Examenes - usuario</h1>
        <table class="table mt-5 text-center">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Nombre del examen</th>
                    <th>Puntuacion</th>
                    <th>Fecha de actualizacion</th>
                    <th>Documento</th>
                </tr>
                </thead>
                <tbody>    
                
                {examen.map(test=>(
                <tr key={test.ex_id}>
                    <td>{test.ex_id}</td>
                    <td>{test.ex_name}</td>
                    <td>{test.score}</td>
                    <td>{test.ver_date}</td>
                    <td><ExusrEdit test={test}></ExusrEdit></td>
                    {/*<td><button className='btn btn-warning'>Ingresar a la prueba</button></td>
                     <td><EditBD usuarios={usuarios}></EditBD></td>
                    <td><button className="btn btn-danger" 
                    onClick={()=>deleteTodo(usuarios.usr_id)}
                    >Eliminar</button></td> */}
                </tr>
                ))}
            </tbody>
        </table>
        </Fragment>
    );
}
export default Ex_usr;