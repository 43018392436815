import React, { Fragment ,useState} from "react";

const EditBD = ({usuarios}) => {
    const [fs_name,setNombre] = useState(usuarios.fs_name);

    const updateBD = async e =>{
        e.preventDefault();
        try {
            const body ={fs_name};
            const response = await fetch(`http://localhost:5000/todos/${usuarios.usr_id}`,{
                method:"PUT",
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify(body)
            });
            console.log(response);
            window.location="/";
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <Fragment>
            <button type="button" 
            class="btn btn-warning" 
            data-toggle="modal" 
            data-target={`#id${usuarios.usr_id}`}>
            Editar
            </button>
            <div class="modal" id={`id${usuarios.usr_id}`}>
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Actualizando campo seleccionado</h4>
                    <button type="button" class="close" data-dismiss="modal"
                    onClick={()=>setNombre(usuarios.fs_name)}>&times;</button>
                </div>
                <div class="modal-body">
                    <input type="text" className="form-control" 
                    value={fs_name} 
                    onChange={e=>setNombre(e.target.value)}></input>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                    class="btn btn-success" 
                    data-dismiss="modal"
                    onClick={e =>updateBD(e)}
                    >Aceptar</button>
                    <button type="button" 
                    class="btn btn-danger" 
                    data-dismiss="modal"
                    onClick={()=>setNombre(usuarios.fs_name)}
                    >Cancelar</button>
                </div>
                </div>
            </div>
            </div>
        </Fragment>
    )
}

export default EditBD;