import React,{Fragment,useState} from "react";

const InputBD =()=>{
  const[fs_name,setName]=useState("");
  const[lst_name,setLstname] = useState("");
  const[birthdate,setBdate] = useState("");
  const[liame,setCorreo] = useState("");
  const[pass,setPwd] = useState("");
  const[depa,setDpt] = useState("");
  const[puesto,setPuesto] = useState("");
  const[log_date,setLogdate] = useState("");
  const[foto,setFoto] = useState("");

  const onSubmitForm = async e =>{
    e.preventDefault();
    try {
        const body ={fs_name,lst_name,birthdate,liame,pass,depa,puesto,log_date,foto};
        const response = await fetch("http://localhost:5000/todos",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(body)
    });
    console.log(response);
    window.location="/";
    } catch (error) {
        console.error(error.message);
    }
  };
  return(
    <Fragment> 
      <h1 className="text-center mt-5">Admin - Ingreso de datos de usuario</h1>
      <form className="d-flex mt-5" onSubmit={onSubmitForm}>
        <input placeholder="Nombre(s)" type="text" className="form-control" value={fs_name} onChange={e => setName(e.target.value)}/>
        <input placeholder="Apellidos" type="text" className="form-control" value={lst_name} onChange={e => setLstname(e.target.value)}/>
        <input placeholder="Fecha de nacimiento" type="text" className="form-control" value={birthdate} onChange={e => setBdate(e.target.value)}/>
        <input placeholder="Email" type="text" className="form-control" value={liame} onChange={e => setCorreo(e.target.value)}/>
        <input placeholder="Clave" type="text" className="form-control" value={pass} onChange={e => setPwd(e.target.value)}/>
        <input placeholder="Departamento" type="text" className="form-control" value={depa} onChange={e => setDpt(e.target.value)}/>
        <input placeholder="Puesto" type="text" className="form-control" value={puesto} onChange={e => setPuesto(e.target.value)}/>
        <input placeholder="Fecha de registro" type="text" className="form-control" value={log_date} onChange={e => setLogdate(e.target.value)}/>
        <input placeholder="Iniciales" type="text" className="form-control" value={foto} onChange={e => setFoto(e.target.value)}/>
        
        <button className="btn btn-success">Add</button>
      </form>
    </Fragment>
  );
};

export default InputBD;