import React, { Fragment ,useState} from "react";

const VideosusrEdit = ({vid}) => {
    const [name_v,setNombre] = useState(vid.v_name);

    const updateBD = async e =>{
        e.preventDefault();
        try {
            const body ={name_v};
            const response = await fetch(`http://localhost:5000/video/${vid.v_id}`,{
                method:"PUT",
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify(body)
            });
            console.log(response);
            window.location="/";
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <Fragment>
            <button type="button" 
            class="btn btn-warning" 
            data-toggle="modal" 
            data-target={`#id_video${vid.v_id}`}
            
            >
            Ver pdf
            </button>
            <div class="modal" id={`id_video${vid.v_id}`}>
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" 
                    title="Hello there"
                    >{vid.v_name}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    {/* <input type="text" className="form-control" 
                    >
                    </input> */}
                    <h4>Hello there! this is your <a href={vid.media}>link</a> :D</h4>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                    class="btn btn-success" 
                    data-dismiss="modal"
                    // onClick={e =>updateBD(e)}
                    >Aceptar</button>
                    <button type="button" 
                    class="btn btn-danger" 
                    data-dismiss="modal"
                    // onClick={()=>setNombre(policy_gen.pol_id)}
                    >Cancelar</button>
                </div>
                </div>
            </div>
            </div>
        </Fragment>
    )
}

export default VideosusrEdit;