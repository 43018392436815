//import {useNavigate} from 'react-router-dom';
//import {c_log} from './Mods'
//import TextField from '@mui/material/TextField'
import '../index.css'
import React,{Fragment,useState,useEffect} from "react"
import VideosusrEdit from './Videos_usr_Edit';

const Videos_usr =() =>{
    
        const[video,setTodos] = useState([]);
        
        const getTodos = async ()=>{
        try {
            const response = await fetch("http://localhost:5000/video");
            const jsonData = await response.json();
            setTodos(jsonData);
            } catch (error) {
                console.error(error.message)
            }
        }
    
    useEffect(()=>{
        getTodos();
    },[]);

    console.log(video);

    return(
        <Fragment> 
        
        <h1 className="text-center mt-5">Videos - usuario</h1>
        <table class="table mt-5 text-center">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Nombre del Video/Curso</th>
                    <th>Enlace al video</th>
                </tr>
                </thead>
                <tbody>    
                
                {video.map(vid=>(
                <tr key={vid.v_id}>
                    <td>{vid.v_id}</td>
                    <td>{vid.v_name}</td>
                    <td><VideosusrEdit vid={vid}></VideosusrEdit></td>
                    {/*<td><button className='btn btn-warning'>Ver video</button></td>
                    <td><EditBD usuarios={usuarios}></EditBD></td>
                    <td><button className="btn btn-danger" 

                    <td>{vid.media}</td>
                    
                    onClick={()=>deleteTodo(usuarios.usr_id)}
                    >Eliminar</button></td> */}
                </tr>
                ))}
            </tbody>
        </table>
        </Fragment>
    );
}
export default Videos_usr;