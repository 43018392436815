import React,{Fragment,useState} from "react";

const Input_ex =()=>{
  const[name_ex,setName]=useState("");
  const[scr_ex,setLstname] = useState("");
  const[qst_ex_cb,setBdate] = useState("");
  const[c_ans_ex,setCorreo] = useState("");
  const[i_ans_ex,setPwd] = useState("");
  const[ver_date_ex,setDpt] = useState("");
  const[doc_ex,setDoc] = useState("");

  const onSubmitForm = async e =>{
    e.preventDefault();
    try {
        const body ={name_ex,scr_ex,qst_ex_cb,c_ans_ex,i_ans_ex,ver_date_ex,doc_ex};
        const response = await fetch("http://localhost:5000/examen",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(body)
    });
    console.log(response);
    window.location="/";
    } catch (error) {
        console.error(error.message);
    }
  };
  return(
    <Fragment> 
      <h1 className="text-center mt-5">Admin - Ingreso de datos de examenes</h1>
      <form className="d-flex mt-5" onSubmit={onSubmitForm}>
        <input placeholder="Nombre" type="text" className="form-control" value={name_ex} onChange={e => setName(e.target.value)}/>
        <input placeholder="Puntuacion" type="text" className="form-control" value={scr_ex} onChange={e => setLstname(e.target.value)}/>
        <input placeholder="Pregunta" type="text" className="form-control" value={qst_ex_cb} onChange={e => setBdate(e.target.value)}/>
        <input placeholder="Respuesta correcta" type="text" className="form-control" value={c_ans_ex} onChange={e => setCorreo(e.target.value)}/>
        <input placeholder="Respuesta erronea" type="text" className="form-control" value={i_ans_ex} onChange={e => setPwd(e.target.value)}/>
        <input placeholder="Fecha" type="text" className="form-control" value={ver_date_ex} onChange={e => setDpt(e.target.value)}/>
        <input placeholder="Enlace" type="text" className="form-control" value={doc_ex} onChange={e => setDoc(e.target.value)}/>
        <button className="btn btn-success">Add</button>
      </form>
    </Fragment>
  );
};

export default Input_ex;