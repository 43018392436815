//import {useNavigate} from 'react-router-dom';
//import {c_log} from './Mods'
import '../index.css'
import React,{Fragment,useState,useEffect} from "react"
import PoliticasEdit from './Politicas_Edit';
// import TextField from '@mui/material/TextField'

const Main =() =>{
    
        const[pols,setTodos] = useState([]);
        
        const getPols = async ()=>{
        try {
            const response = await fetch("http://localhost:5000/pols");
            const jsonData = await response.json();
            setTodos(jsonData);
            } catch (error) {
                console.error(error.message)
            }
        }
    
    useEffect(()=>{
        getPols();
    },[]);

    console.log(pols);

    return(
        <Fragment> 
       
        {/* 
        <div className='App'>
            <div className='App_panel'>
            <div className='login_panel'>
                    <div className='panel_btn_user'>
                        <label class="container" >Usuario
                            <input id='btn_user' type="radio" name="radio" ></input>
                            <span class="checkmark"></span>
                        </label>
                    </div>    
                    <div className='panel_btn_admin'>
                        <label class="container" >Administrador
                            <input id='btn_admn' type="radio" name="radio" ></input>
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div>
                    <form action='/sign_in' method='GET'>
                    <div className='panel_btn_login' ><button>Iniciar sesión</button></div>
                    
                    <div className='panel_text_input'>
                        <div id="input_a">
                        <TextField
                                        margin="auto"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Dirección de correo"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    
                        </div>
                        <div id='input_b'>
                                <TextField
                                        margin="auto"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    /> 
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
                <div className='img_panel'><img id='img' src="./Logo_empresa.jpeg" title='webito referencia osihermano 7u7' alt='nice'/></div>
            </div>
        </div> */}

        <h1 className="text-center mt-5">Politicas - usuario</h1>
        <table class="table mt-5 text-center">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Nombre de politica</th>
                    <th>Nivel</th>
                    <th>Fecha de actualizacion</th>
                    <th>Versión</th>
                    <th>Documento</th>
                </tr>
                </thead>
                <tbody>    
                
                {pols.map(policy_gen=>(
                <tr key={policy_gen.pol_id}>
                    <td>{policy_gen.pol_id}</td>
                    <td>{policy_gen.pol_name}</td>
                    <td>{policy_gen.nivel}</td>
                    <td>{policy_gen.ver_date}</td>
                    <td>{policy_gen.vers}</td>
                    {/* <td><button className='btn btn-warning'>Ver pdf</button></td> */}
                    <td><PoliticasEdit policy_gen={policy_gen}></PoliticasEdit></td>
                     {/*<td><button className="btn btn-danger" 
                    onClick={()=>deleteTodo(usuarios.usr_id)}
                    >Eliminar</button></td> */}
                </tr>
                ))}
            </tbody>
        </table>
        </Fragment>
    );
}
export default Main;