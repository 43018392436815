import React,{Fragment,useState} from "react";

const Input_combol =()=>{
    
  const[name_cb,setName]=useState("");
  const[lvl_cb,setLstname] = useState("");
  const[dpt_pol_cb,setBdate] = useState("");
  const[pol_vers_cb,setCorreo] = useState("");
  const[vers_date_cb,setPwd] = useState("");
  const[doc_cb,setDpt] = useState("");

  const onSubmitForm = async e =>{
    e.preventDefault();
    try {
        const body ={name_cb,lvl_cb,dpt_pol_cb,pol_vers_cb,vers_date_cb,doc_cb};
        const response = await fetch("http://localhost:5000/combol",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(body)
    });
    console.log(response);
    window.location="/";
    } catch (error) {
        console.error(error.message);
    }
  };
  return(
    <Fragment> 
      <h1 className="text-center mt-5">Admin - Ingreso de datos comunicados y boletines</h1>
      <form className="d-flex mt-5" onSubmit={onSubmitForm}>
        <input placeholder="Nombre" type="text" className="form-control" value={name_cb} onChange={e => setName(e.target.value)}/>
        <input placeholder="Nivel" type="text" className="form-control" value={lvl_cb} onChange={e => setLstname(e.target.value)}/>
        <input placeholder="Departamento" type="text" className="form-control" value={dpt_pol_cb} onChange={e => setBdate(e.target.value)}/>
        <input placeholder="Version" type="text" className="form-control" value={pol_vers_cb} onChange={e => setCorreo(e.target.value)}/>
        <input placeholder="Fecha" type="text" className="form-control" value={vers_date_cb} onChange={e => setPwd(e.target.value)}/>
        <input placeholder="Enlace del documento" type="text" className="form-control" value={doc_cb} onChange={e => setDpt(e.target.value)}/>
        <button className="btn btn-success">Add</button>
      </form>
    </Fragment>
  );
};

export default Input_combol;