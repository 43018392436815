import React,{Fragment,useState} from "react";

const Input_vid =()=>{
  const[name_v,setName]=useState("");
  const[media_v,setLstname] = useState("");
  
  const onSubmitForm = async e =>{
    e.preventDefault();
    try {
        const body ={name_v,media_v};
        const response = await fetch("http://localhost:5000/video",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(body)
    });
    console.log(response);
    window.location="/";
    } catch (error) {
        console.error(error.message);
    }
  };
  return(
    <Fragment> 
      <h1 className="text-center mt-5">Admin - Ingreso de datos de videos</h1>
      <form className="d-flex mt-5" onSubmit={onSubmitForm}>
        <input placeholder="Nombre" type="text" className="form-control" value={name_v} onChange={e => setName(e.target.value)}/>
        <input placeholder="Enlace al video" type="text" className="form-control" value={media_v} onChange={e => setLstname(e.target.value)}/>
        <button className="btn btn-success">Add</button>
      </form>
    </Fragment>
  );
};

export default Input_vid;