import React,{Fragment} from "react";
import "./App.css";


//Componentes
import Main from './pages/Main'
import Com_bol_usr from "./pages/Com_bol";
import InputBD from "./components/InputBD";
import ListBD from "./components/ListDB"; 
import Input_pol from "./components/Input_pol";
import Ex_usr from "./pages/Ex_usr";
import Videos_usr from "./pages/Videos_usr";
import Input_combol from "./components/Input_combol";
import Input_ex from "./components/Input_ex";
import Input_vid from "./components/Input_vid";


function App (){
  //const[description,setDescription]=useState("");
  return(
    <>
    <Fragment> 
      <div>
        <InputBD></InputBD>
        <ListBD></ListBD>
      </div> 
      <div>
      <Input_pol></Input_pol>
      <Main></Main>
      </div>
      <Input_combol></Input_combol>
      <Com_bol_usr></Com_bol_usr>
      <Input_ex></Input_ex>
      <Ex_usr></Ex_usr>
      <Input_vid></Input_vid>
      <Videos_usr></Videos_usr>
    </Fragment>
    </>
  );
}

export default App