import React, { Fragment, useState,useEffect} from "react";

import EditBD from "./EditBD";

    const ListDB=()=>{

        const[todos,setTodos] = useState([]);
        
        const deleteTodo = async id =>{
            try {
                const deleteTodo = await fetch(`http://localhost:5000/todos/${id}`,{
                    method:"DELETE"
                });
                setTodos(todos.filter(usuarios=>usuarios.usr_id !== id));
                //console.log(deleteTodo);
            } catch (error) {
                console.error(error.message);
            }
        }

        const getTodos = async ()=>{
        try {
            const response = await fetch("http://localhost:5000/todos");
            const jsonData = await response.json();
            setTodos(jsonData);
            } catch (error) {
                console.error(error.message)
            }
        }
    
    useEffect(()=>{
        getTodos();
    },[]);

    console.log(todos);
    
    return(
    <Fragment>
        <h1 className="text-center mt-5">Lista de usuarios</h1>
        <table class="table mt-5 text-center">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Nombre(s)</th>
                    <th>Apellidos</th>
                    <th>Actualizar</th>
                    <th>Borrar</th>
                </tr>
                </thead>
                <tbody>    
                
                {todos.map(usuarios=>(
                <tr key={usuarios.usr_id}>
                    <td>{usuarios.usr_id}</td>
                    <td>{usuarios.fst_name}</td>
                    <td>{usuarios.ls_name}</td>
                    <td><EditBD usuarios={usuarios}></EditBD></td>
                    <td><button className="btn btn-danger" 
                    onClick={()=>deleteTodo(usuarios.usr_id)}
                    >Eliminar</button></td>
                </tr>
                ))}
            </tbody>
        </table>
    </Fragment>
    );
};
    
export default ListDB;