//import {useNavigate} from 'react-router-dom';
//import {c_log} from './Mods'
//import TextField from '@mui/material/TextField'
import '../index.css'
import React,{Fragment,useState,useEffect} from "react"
import CombolEdit from './Com_bol_Edit';

const Com_bol_usr =() =>{
    
        const[combol,setTodos] = useState([]);
        
        const getTodos = async ()=>{
        try {
            const response = await fetch("http://localhost:5000/combol");
            const jsonData = await response.json();
            setTodos(jsonData);
            } catch (error) {
                console.error(error.message)
            }
        }
    
    useEffect(()=>{
        getTodos();
    },[]);

    console.log(combol);

    return(
        <Fragment> 
        
        <h1 className="text-center mt-5">Comunicados y boletines - usuario</h1>
        <table class="table mt-5 text-center">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Nombre de comunicado/boletin</th>
                    <th>Nivel</th>
                    <th>Fecha de actualizacion</th>
                    <th>Versión</th>
                    <th>Documento</th>
                </tr>
                </thead>
                <tbody>    
                
                {combol.map(com_bol=>(
                <tr key={com_bol.cb_id}>
                    <td>{com_bol.cb_id}</td>
                    <td>{com_bol.cb_name}</td>
                    <td>{com_bol.nivel}</td>
                    <td>{com_bol.vers_date}</td>
                    <td>{com_bol.vers}</td>
                    <td><CombolEdit com_bol={com_bol}></CombolEdit></td>
                    {/* 
                    <td><button className='btn btn-warning'>Ver pdf</button></td>
                    <td><EditBD usuarios={usuarios}></EditBD></td>
                    <td><button className="btn btn-danger" 
                    onClick={()=>deleteTodo(usuarios.usr_id)}
                    >Eliminar</button></td> */}
                </tr>
                ))}
            </tbody>
        </table>
        </Fragment>
    );
}
export default Com_bol_usr;