import React,{Fragment,useState} from "react";

const Input_pol =()=>{
   
  const[name_pol,setName]=useState("");
  const[pol_lvl,setLstname] = useState("");
  const[pol_dpt,setBdate] = useState("");
  const[pol_doc,setCorreo] = useState("");
  const[pol_vers,setPwd] = useState("");
  const[pol_vers_date,setDpt] = useState("");
 
  const onSubmit_Adm_pol = async e =>{
    e.preventDefault();
    try {
        const body ={name_pol,pol_lvl,pol_dpt,pol_doc,pol_vers,pol_vers_date};
        const response = await fetch("http://localhost:5000/pols",{
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify(body)
    });
    console.log(response);
    window.location="/";
    } catch (error) {
        console.error(error.message);
    }
  };
  return(
    <Fragment> 
      <h1 className="text-center mt-5">Admin - Ingreso de datos politicas</h1>
      <form className="d-flex mt-5" onSubmit={onSubmit_Adm_pol}>
        <input placeholder="Nombre" type="text" className="form-control" value={name_pol} onChange={e => setName(e.target.value)}/>
        <input placeholder="Nivel" type="text" className="form-control" value={pol_lvl} onChange={e => setLstname(e.target.value)}/>
        <input placeholder="Departamento" type="text" className="form-control" value={pol_dpt} onChange={e => setBdate(e.target.value)}/>
        <input placeholder="Enlace al documento" type="text" className="form-control" value={pol_doc} onChange={e => setCorreo(e.target.value)}/>
        <input placeholder="Versión" type="text" className="form-control" value={pol_vers} onChange={e => setPwd(e.target.value)}/>
        <input placeholder="Fecha de registro" type="text" className="form-control" value={pol_vers_date} onChange={e => setDpt(e.target.value)}/>
        
        <button className="btn btn-success">Add</button>
      </form>
    </Fragment>
  );
};

export default Input_pol;